import * as React from "react"
import { StoreProvider } from "./src/context/store-context"
import 'swiper/css'
import 'swiper/css/navigation'

import "./src/scss/typography.scss"

export const wrapRootElement = ({ element }) => (
    <StoreProvider>{element}</StoreProvider>
)
  
